@import '@/styles/variables.scss';

#timelineWrap {
  background-color: $white;
  td.fc-datagrid-cell.area-cell,
  td.fc-datagrid-cell.staff-cell[data-resource-id*='Null'],
  td.fc-timeline-lane[data-resource-id*='Null'],
  td.fc-datagrid-cell.staff-cell[data-resource-id='canceled'],
  td.fc-timeline-lane[data-resource-id='canceled'] {
    border-bottom: $black solid 1px;
  }
  td.fc-datagrid-cell[class$='--ResourceBgColor--gray'],
  td.fc-datagrid-cell.staff-cell[data-resource-id*='--ResourceBgColor--gray'],
  td.fc-timeline-lane[data-resource-id*='--ResourceBgColor--gray'] {
    background-color: $appointment-timeline-odd-bg-gray;
  }
  td.fc-datagrid-cell.area-cell[data-resource-id='canceled'] {
    border-right-style: none;
  }
  td.fc-datagrid-cell.staff-cell[data-resource-id='canceled'] {
    border-left-style: none;
  }
  // bottomのborderを表示させる
  .fc .fc-scrollgrid-section-body table {
    border-bottom-style: solid;
  }
  .fc-view-harness {
    height: calc(100vh - 180px) !important; // timelineの大きさ
  }
  // header
  .fc-scrollgrid-section-header {
    background-color: $main-bg;
  }
  // eventが表示されるrow
  .fc-timeline-lane {
    height: 82px;
  }
  // drag時にselectされた背景
  .fc-highlight {
    height: 82px;
  }
  // 査定員にあたる列
  .fc-resource {
    height: 82px;
  }
  // 現在時刻に表示される赤い線
  .fc-timeline-now-indicator-container {
    z-index: 1;
  }
  //event 予約概要カード
  .fc-event {
    background-color: $white;
    padding: 2px 4px 2px 4px;
    border-radius: 4px;
    border: 1px solid $label-gray;
  }
  .fc-event-main {
    color: $black;
    position: relative;
    padding: 2px;
  }
  //event 都道府県カラー
  .fc-event.lavender {
    background-color: $appointment-event-pref-bg-lavender;
    border-style: none;
  }
  .fc-event.sage {
    background-color: $appointment-event-pref-bg-sage;
    border-style: none;
  }
  .fc-event.grape {
    background-color: $appointment-event-pref-bg-grape;
    border-style: none;
  }
  .fc-event.flamingo {
    background-color: $appointment-event-pref-bg-flamingo;
    border-style: none;
  }
  .fc-event.banana {
    background-color: $appointment-event-pref-bg-banana;
    border-style: none;
  }
  .fc-event.orange {
    background-color: $appointment-event-pref-bg-orange;
    border-style: none;
  }
  .fc-event.peacock {
    background-color: $appointment-event-pref-bg-peacock;
    border-style: none;
  }
  .fc-event.graphite {
    background-color: $appointment-event-pref-bg-graphite;
    border-style: none;
  }
  .fc-event.blueberry {
    background-color: $appointment-event-pref-bg-blueberry;
    border-style: none;
  }
  .fc-event.basil {
    background-color: $appointment-event-pref-bg-basil;
    border-style: none;
  }
  .fc-event.tomato {
    background-color: $appointment-event-pref-bg-tomato;
    border-style: none;
  }
  .fc-event.cocoa {
    background-color: $appointment-event-pref-bg-cocoa;
    border-style: none;
  }
  .fc-event.lemon {
    background-color: $appointment-event-pref-bg-lemon;
    border-style: none;
  }
  .fc-event.avocado {
    background-color: $appointment-event-pref-bg-avocado;
    border-style: none;
  }
  .fc-event.white {
    background-color: $appointment-event-pref-bg-white;
    border-style: none;
  }
  //event 黒枠
  .fc-event.primary {
    border: 1px solid $label-sky;
  }
  //event 色を薄く
  .fc-event.translucent {
    .fc-event-main {
      opacity: 0.5;
    }
  }
  //event 赤枠
  .fc-event.secondary {
    border: 1px solid $label_red;
  }
}
